.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-top: solid 1px #dadada;
}

.card-container:first-child {
  border-top: none;
}

.card-container.confirmed {
  cursor: pointer;
}

.card-container.selected {
  border-top: none;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.card-container.selected + .card-container {
  border-top: none;
}

.card-container h5,
.card-container h4,
.card-container p {
  font-size: 0.8rem;
  margin: 0;
}

.card-container h4,
.card-container h5 {
  color: #e31f2b;
  font-weight: 500;
}

.card-container h5 {
  font-weight: 700;
}

.card-container.confirmed h4,
.card-container.confirmed h5 {
  color: #69cb63;
}

.card-container h4 span {
  font-weight: 900;
  font-size: 1rem;
}

.card-container p {
  color: #bbbbbb;
}
