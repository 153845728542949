.map-container {
  height: 45vh;
  width: 100%;
  overflow: hidden;
}

.map-container .map-marker {
  color: #e31f2b;
  width: 36px;
  height: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -18px;
}

.map-container .client-marker {
  color: #69cb63;
  width: 25px;
  height: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}

.map-container .selected-marker {
  width: 30px;
  margin-left: -15px;
}

.map-container .selected-marker .tooltip {
  position: relative;
  display: inline-block;
}

.map-container .selected-marker .tooltip .tooltiptext {
  width: 120px;
  background-color: #f8c9d7;
  color: #e31f2b;
  text-align: center;
  border-radius: 15px;
  padding: 5px;
  border: 1px solid #e31f2b;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  bottom: 32px;
  left: 50%;
  margin-left: -65px;
}

.map-container .selected-marker .tooltip .tooltiptext::before {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  border-width: 12px;
  border-style: solid;
  border-color: #e31f2b transparent transparent transparent;
}

.map-container .selected-marker .tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #f8c9d7 transparent transparent transparent;
}
