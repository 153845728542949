.app .logo {
  height: 40vmin;
  pointer-events: none;
  margin: 2rem 0;
}

@media screen and (min-width: 500px) {
  .app .logo {
    height: 20vmin;
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .Login-logo {
    animation: Login-logo-spin infinite 20s linear;
  }
} */

.app .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  color: black;
  font-size: 0.6rem;
  max-width: 300px;
  margin: 1rem auto 0;
}

.app .login-form select {
  width: 100%;
  padding: 0.9rem 0.5rem;
  border: none;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  background: none;
  margin-bottom: 0.7rem;
}

.app .login-form select option:disabled,
.app .login-form select:invalid {
  color: #fafafa;
}

.app .login-form input[type='password'] {
  width: 100%;
  padding: 0.9rem 0.9rem;
  border: none;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.7rem;
}

.app .login-form .password-fieldset,
.app .login-form .username-fieldset {
  width: 100%;
}

.app .login-form .checkbox-fieldset {
  align-self: flex-start;
  margin-bottom: 0.7rem;
}

.app .login-form label {
  color: #bcbcbb;
}

.app .login-form input[type='submit'] {
  cursor: pointer;
  background-color: #38ce54;
  color: white;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem 4rem;
  font-weight: 900;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  font-size: 1rem;
}

/* @keyframes Login-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
