.app {
  text-align: center;
  margin: 0 auto;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
