.main-header .local-name {
  color: #e31f2b;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.main-header .local-address {
  color: #e31f2b;
  margin: 0 0 0.8rem;
}

.cards-container {
  box-sizing: border-box;
  margin: 0.5rem;
}
